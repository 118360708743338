import React from 'react';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import mission from '../../assets/icons/mission.png';
import mission_bg from '../../assets/icons/mission_bg.png';
import vision from '../../assets/icons/vision.png';
import vision_bg from '../../assets/icons/vision_bg.png';
import values from '../../assets/icons/values.png';
import values_bg from '../../assets/icons/values_bg.png';
import donation from '../../assets/icons/donation.png';
import teens from '../../assets/icons/teens.png';
import fundraise from '../../assets/icons/fundraise.png';
import styles from './OurDrive.module.scss';

const cores = [
  {
    id: 1,
    title: 'Our Mission',
    text: "Our mission is to empower teenagers to reach their full potential, promote personal growth, and instill values of purity and dignity.",
    icon: [mission, mission_bg],
  },
  {
    id: 2,
    title: 'Our Vision',
    text: 'We envision a world where empowered teenagers shine brightly in their communities, guided by their values and potential.',
    icon: [vision, vision_bg],
  },
  {
    id: 3,
    title: 'Our Values',
    text: 'Purity, Respect, Excellence, Creativity, and Dignity are the guiding principles that shape everything we do at Blaizing Teens Initiative.',
    icon: [values, values_bg],
  },
];

const stats = [
  {
    id: 1,
    icon: donation,
    title: 'Total Donations',
    value: 10,
  },
  {
    id: 2,
    icon: teens,
    title: 'Teens Reached',
    value: 12,
  },
  {
    id: 3,
    icon: fundraise,
    title: 'Fundraise Goals',
    value: 100
  },
];

const OurDrive = () => {
  return (
    <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
    <div className={styles.wrapper}>
      <motion.article
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        viewport={{ once: true }}
        className={styles.header}
      >
        <p className={styles.subTitle}>Our Drive</p>
        <h2 className={styles.title}>providing support for
          <span className={styles.shade}> those in need</span>
        </h2>
      </motion.article>

      <motion.article className={styles.content}>
        <motion.article
          className={styles.textContent}
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            className={styles.text}
          >
            Our purpose aligns closely with the United Nations'
            Sustainable Development Goals (SDGs) by creating a supportive and transformative
            environment where teenagers are empowered to realize their full potential.
            Our efforts contribute to
            <span> SDG 4: Quality Education </span>
            by providing education, mentorship, and resources that foster excellence
            and creativity. We also support
            <span> SDG 5: Gender Equality </span>
            by promoting dignity, respect, and equal opportunities for all teenagers, regardless of gender.
          </motion.p>

          <motion.p
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            className={styles.text}
          >
            Additionally, by inspiring young minds to become leaders and innovators in their generation,
            we advance <span> SDG 8: Decent Work and Economic Growth</span>, ensuring that teenagers are equipped
            with the skills and values needed to contribute meaningfully to society.
            Our commitment to upholding purity and respect in all aspects of life also resonates with
            <span> SDG 16: Peace, Justice, and Strong Institutions</span>, as we guide teens to be responsible,
            ethical, and impactful members of their communities. Through these efforts, 
            we play a vital role in shaping a future where teenagers can shine
            brightly in their communities and the world, in alignment with the global goals for sustainable development.
          </motion.p>
          <article className={styles.perksDiv}>
            {cores.map((perk) => (
              <motion.article
                initial={{ opacity: 0, y: 150 }}
                whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
                className={styles.perk}
                key={perk.id}
                viewport={{ once: true }}
                style={{
                  background: `url(${perk.icon[1]}), var(--light)`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '20%',
                  backgroundPosition: '105% 107%',
                }}
              >
                <div className={styles.iconDiv}>
                  <img src={perk.icon[0]} alt={perk.title} className={styles.icon} />
                </div>
                <div className={styles.div}>
                  <h5 className={styles.heading}>{perk.title}</h5>
                  <p className={styles.text}>{perk.text}</p>
                </div>
              </motion.article>
            ))}
          </article>
        </motion.article>

        <article className={styles.stats}>
          {stats.map((stat) => (
            <motion.article
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, type: 'spring', stiffness: 150 }}
              key={stat.id}
              className={styles.stat}
            >
              <img src={stat.icon} alt={stat.title} className={styles.icon} />
              <div className={styles.bullets}>
                <h4 className={styles.number}>
                {stat.id !== 2 && <span className={styles.mark}>₦</span>}
                  <CountUp start={0} end={stat.value} separator="," decimal="." duration={15} />
                  <span className={styles.mark}>
                    {stat.id === 1 || stat.id === 3 ? 'M' : 'K'}
                    +
                  </span>
                </h4>
                <p className={styles.text}>{stat.title}</p>
              </div>
            </motion.article>
          ))}
        </article>
      </motion.article>
      </div>
  </motion.section>
  )
}

export default OurDrive