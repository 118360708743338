import { v4 as uuidv4 } from 'uuid';
import event from '../../assets/images/pro_1.png';
import onePad from '../../assets/events/one_pad.jpeg';
import styles from './Content.module.scss';

const selfDiscovery = (
  <>
    <p class={styles.text}>On July 14th 2024, Blaizing Teens Initiative successfully hosted a dynamic webinar focused on the profound theme of self-discovery. This event brought together teens, educators, and experts to share insights and strategies for navigating the path to personal growth.</p>
    <h3 className={styles.subHeader}>Highlights from the Webinar:</h3>
    <ol className={styles.list}>
      <li className={styles.listItem}><strong>Inspiring Speaker Presentations:</strong> 
        <p>Our lineup of speakers included youth advocates and personal development coaches who shared their own journeys of self-discovery. They discussed how understanding one’s values, strengths, and passions can lead to a more fulfilling life. Participants resonated with stories of overcoming obstacles and embracing authenticity.</p>
      </li>
      <li className={styles.listItem}><strong>Interactive Discussions:</strong> 
        <p className={styles.text}>The webinar featured breakout sessions where attendees engaged in open conversations. These discussions encouraged participants to share their experiences and reflect on their individual journeys. The supportive environment fostered a sense of community, allowing teens to connect over shared challenges and aspirations.</p>
      </li>
      <li className={styles.listItem}><strong>Practical Tools for Self-Discovery:</strong> Experts provided actionable strategies for self-exploration, including:
        <ul className={styles.subList}>
          <li className={styles.listItem}><strong>Journaling Techniques:</strong> Participants learned how to use journaling as a tool for reflection and clarity.</li>
          <li className={styles.listItem}><strong>Mindfulness Practices:</strong> Techniques for mindfulness were introduced to help attendees become more aware of their thoughts and feelings.</li>
          <li className={styles.listItem}><strong>Goal Setting Workshops:</strong> Participants were guided in setting realistic and meaningful goals based on their newfound insights.</li>
        </ul>
      </li>
      <li className={styles.listItem}><strong>Positive Impacts on Personal Growth:</strong> 
        <p className={styles.text}>Throughout the session, the positive impacts of self-discovery were emphasized, including:</p>
        <ul className={styles.subList}>
          <li className={styles.listItem}>Enhanced self-esteem and confidence.</li>
          <li className={styles.listItem}>Improved decision-making skills.</li>
          <li className={styles.listItem}>Greater resilience in facing life’s challenges.</li>
        </ul>
      </li>
    </ol>
    <p className={styles.text}>The event concluded with a Question and Answer session, where attendees could ask questions and seek advice from the speakers. This interactive element enriched the experience, making it clear that the journey to self-discovery is both personal and communal.</p>
  </>
);

const listFeb = (
  <>
    <p className={styles.text}>On the vibrant morning of February 5th, 2024, our team embarked on a heartwarming journey to the Community Secondary Commercial School in Iffe Town, Mkpat Enin Local Government Area. With unwavering dedication and a passion for making a difference, we embarked on our mission of reaching the unreached, and the day unfolded with impactful moments and cherished memories.</p>
    <h3 className={styles.subHeader}>Accomplishments:</h3>
    <ul className={styles.list}>
      <li className={styles.listItem}><strong>Empowerment through Education:</strong> Our outreach focused on empowering the youths with knowledge and tools for a brighter future. We distributed essential educational materials such as branded notebooks and biros, providing students with the necessary resources to excel in their academic pursuits.</li>
      <li className={styles.listItem}><strong>Promoting Health and Hygiene:</strong> Recognizing the importance of menstrual hygiene, we distributed sanitary towels and offered guidance on maintaining a healthy lifestyle during menstruation through our booklet "My Cycle and I."</li>
      <li className={styles.listItem}><strong>Guidance on Life Choices:</strong> Our team shared valuable insights on sexual purity and the importance of making wise decisions in life and relationships through the guide "No Wed, No Bed: A Guide to sexual Purity.</li>
      <li className={styles.listItem}><strong>Aspiring Towards the Nigeria Dream::</strong> Through our booklet "The Nigeria Dream," we instilled hope and inspiration in the hearts of the young minds, encouraging them to dream big and strive for excellence in all their endeavors.</li>
    </ul>
    <h3 className={styles.subHeader}>Impact and Successes:</h3>
    <ul className={styles.list}>
      <li className={styles.listItem}><strong>Empowered Minds, Transformed Lives:</strong> Witnessing the eagerness and enthusiasm of the students as they received the educational materials and absorbed the empowering messages was truly heartening. We believe that these seeds of knowledge and inspiration will flourish, transforming lives and shaping futures.</li>
      <li className={styles.listItem}><strong>Promoting Health and Well-being:</strong> By addressing topics such as menstrual hygiene and sexual purity, we aimed to break stigmas and promote healthy practices, fostering a community where individuals can thrive physically, emotionally, and mentally."</li>
      <li className={styles.listItem}><strong>Fostering Hope and Aspiration: :</strong> The presence of the representative from the Local Government Chairman's office added a touch of encouragement and validation to our cause. Their words of wisdom, affirming that future leaders can emerge from rural areas, resonated deeply with the students, igniting sparks of ambition and determination within them.</li>,
    </ul>
    <p className={styles.text}>We extend our heartfelt gratitude to the representative from the Local Government Chairman's office for their inspiring words and unwavering support. We also thank our dedicated team members and volunteers whose commitment and enthusiasm made this outreach possible.</p>
    <p className={styles.text}>Together, we have taken a significant step towards empowering the youth, fostering positive change, and building a brighter tomorrow. As we reflect on the success of this outreach, let us continue to strive for excellence and remain steadfast in our commitment to making a difference in the lives of those we serve.</p>
  </>
);

const pad = (
  <>
    <p className={styles.text}>Earlier today, we had the privilege of visiting a Commercial Community Secondary School, nestled in the rural beauty of Akwa Ibom State, to celebrate World Menstrual Hygiene Day, with the theme "Together for a #PeriodFriendlyWorld#". As a team, we embarked on a journey of education, empowerment, and advocacy.</p>
    <p className={styles.text}>In the heart of this vibrant community, we joined hands with students, teachers, and local leaders to break the silence surrounding menstrual hygiene. Through engaging workshops, discussions, and interactive sessions, we shared vital information on menstrual health, debunked myths, and highlighted the importance of access to menstrual products.</p>
    <p className={styles.text}>We also championed inclusivity and dignity, striving to create a world where menstruation is embraced without shame or stigma. From distributing hygiene kits to initiating dialogue on menstrual equity, every step we took was a testament to our commitment to building a more PERIOD FRIENDLY WORLD.</p>
    <p className={styles.text}>As we bid farewell to this remarkable community, we carry with us the memories of shared laughter, learning, and solidarity. Let us continue to stand together, amplifying voices, and advocating for menstrual health and rights worldwide. Together, we can make a difference —ONE GIRL, ONE PAD.</p>
  </>
);

const pushingBoundaries = (
  <>
    <p className={styles.text}>Today, let's talk about the incredible power of learning and why it's so essential for your journey ahead.</p>
    <p className={styles.text}>Imagine a world where every mind is like a beacon of light, illuminating new ideas, perspectives, and solutions. That's the world we envision, and it all starts with education.</p>
    <p className={styles.text}>Recently, our team, Blaizing Teens Initiative, had the privilege of visiting a secondary school. It was an eye-opening experience witnessing the enthusiasm and thirst for knowledge among the students. As we engaged with them, we realized the immense potential within each young mind.</p>
    <p className={styles.text}>Education isn't just about memorizing facts or passing exams. It's about unlocking your potential, discovering your passions, and shaping the future you want to see. Every lesson learned, every book read, and every question asked is a step closer to realizing your dreams.</p>
    <p className={styles.text}>During our visit, we were humbled to support these bright minds by providing writing materials. It was a small gesture, but it symbolized our commitment to their educational journey. Education should never be hindered by lack of resources, and we believe every student deserves access to the tools they need to succeed.</p>
    <p className={styles.text}>So, to all the young minds out there, never underestimate the power of education. Embrace every opportunity to learn, explore new ideas, and challenge yourselves. Your journey may have obstacles, but with knowledge as your guide, there's no limit to what you can achieve.</p>
    <p className={styles.text}>Together, let's continue to strive for a world where education is not just a privilege, but a fundamental right for all. Let's blaze trails of knowledge and inspire others to join us on this transformative journey.</p>
  </>
);

const eventData = [
  {
    id: uuidv4(),
    title: 'The Journey To Self-Discovery',
    img: 'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972788/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.32.10_PM_uhutjq.jpg',
    day: '14',
    month: 'July',
    content: [
      selfDiscovery,
    ],
    featureVideo: '',
    otherMedia: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972783/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.11_PM_1_bd4ys0.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972783/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.10_PM_2_zwnp9b.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972783/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.11_PM_ta10bj.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972783/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.22_PM_r8j7ji.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972783/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.26_PM_fr5x50.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972784/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.09_PM_gixwsz.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972784/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.20_PM_tquf4n.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972784/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.09_PM_1_iuq0nu.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972783/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.29_PM_grrdjo.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972784/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.10_PM_dgsqq7.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972788/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.03_PM_lrwv6b.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972788/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.47_PM_rymswu.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972789/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.44_PM_jw1tcx.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972793/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.41_PM_zbfqy9.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972792/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.42_PM_yguohc.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972790/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.43_PM_gmju1i.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972789/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.46_PM_uulpie.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972793/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.40_PM_1_gj4ymn.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972793/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.40_PM_lj9avz.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972794/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.38_PM_bgm8fw.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727972797/blaizingTeens/discovery/WhatsApp_Image_2024-10-01_at_8.34.34_PM_mcpfjq.jpg',
    ],
    eventDetails: {
      date: '14th July, 2024',
      type: 'Personal Development & Education',
      venue: 'The Blaizing Teens Hub (Virtual)',
      attendees: '157 Participants',
    },
  },
  {
    id: uuidv4(),
    title: 'One Girl, One Pad: Celebrating World Menstrual Hygiene Day',
    img: onePad,
    day: '28',
    month: 'May',
    content: [
      pad,
    ],
    featureVideo: '',
    otherMedia: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811552/blaizingTeens/OnePad/_MGL0068_sf4cot.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811552/blaizingTeens/OnePad/_MGL0017_suahcs.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811552/blaizingTeens/OnePad/_MGL0055_w3fv47.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811552/blaizingTeens/OnePad/_MGL0025_q1snxg.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811553/blaizingTeens/OnePad/_MGL0074_i4ilws.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811552/blaizingTeens/OnePad/_MGL0043_wmfzb5.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811553/blaizingTeens/OnePad/_MGL0064_bgowq1.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811553/blaizingTeens/OnePad/_MGL0083_gipyzf.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811553/blaizingTeens/OnePad/_MGL0078_edctmn.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811553/blaizingTeens/OnePad/_MGL0026_x0og4v.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811552/blaizingTeens/OnePad/_MGL0070_vbjvcl.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811553/blaizingTeens/OnePad/_MGL0066_hg0mie.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811554/blaizingTeens/OnePad/_MGL0119_br4vxx.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811553/blaizingTeens/OnePad/_MGL0028_epz44f.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811561/blaizingTeens/OnePad/_MGL0155_cys8ea.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811554/blaizingTeens/OnePad/_MGL0121_nfxteo.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811554/blaizingTeens/OnePad/_MGL0134_rra4ac.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811554/blaizingTeens/OnePad/_MGL0031_ov132s.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811554/blaizingTeens/OnePad/_MGL0041_rdeeco.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811563/blaizingTeens/OnePad/_MGL0135_dwer18.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811555/blaizingTeens/OnePad/_MGL9963_kejr2o.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811572/blaizingTeens/OnePad/_MGL0105_pg9rf5.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811565/blaizingTeens/OnePad/_MGL0123_z2xicv.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811564/blaizingTeens/OnePad/_MGL0166_kpkvou.jpg',
    ],
    eventDetails: {
      date: '28th May, 2024',
      type: 'Health',
      venue: 'Community Secondary School, Ukanafun, Akwa Ibom',
      attendees: '1,457 Participants',
    },
  },
  {
    id: uuidv4(),
    title: 'Pushing Boundaries: A Visit to Community Secondary School',
    img: event,
    day: '25',
    month: 'April',
    content: [
      pushingBoundaries,
    ],
    featureVideo: '',
    otherMedia: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811998/blaizingTeens/unreached/IMG-20240207-WA0079_g1h0fu.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727812000/blaizingTeens/unreached/IMG-20240207-WA0069_hafffc.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727812001/blaizingTeens/unreached/IMG-20240207-WA0071_wpjvez.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727812000/blaizingTeens/unreached/IMG-20240207-WA0073_q21xn2.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727812001/blaizingTeens/unreached/IMG-20240207-WA0066_g1roqk.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727812000/blaizingTeens/unreached/IMG-20240207-WA0075_mv7gg9.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727812000/blaizingTeens/unreached/IMG-20240207-WA0069_hafffc.jpg',
    ],
    eventDetails: {
      date: '25th April, 2024',
      type: 'Education & Charity',
      venue: 'Community Secondary School, Ukanafun, Akwa Ibom',
      attendees: '643 Students',
    }
  },
  {
    id: uuidv4(),
    title: 'Reaching The Unreached: Empowering Youth at Community Secondary Commercial School',
    img: 'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811996/blaizingTeens/unreached/IMG-20240207-WA0049_l1sxfm.jpg',
    day: '5',
    month: 'Feb.',
    content: [
      listFeb,
    ],
    featureVideo: '',
    otherMedia: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811996/blaizingTeens/unreached/IMG-20240207-WA0049_l1sxfm.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727812001/blaizingTeens/unreached/IMG-20240207-WA0066_g1roqk.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727812000/blaizingTeens/unreached/IMG-20240207-WA0077_davh3l.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811999/blaizingTeens/unreached/IMG-20240207-WA0074_vgh5tw.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811999/blaizingTeens/unreached/IMG-20240207-WA0080_kcxvpv.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811997/blaizingTeens/unreached/IMG-20240207-WA0015_sqcepk.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811996/blaizingTeens/unreached/IMG-20240207-WA0057_cgezoa.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811997/blaizingTeens/unreached/IMG-20240207-WA0030_apfsui.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811995/blaizingTeens/unreached/IMG-20240207-WA0056_thjrfi.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811996/blaizingTeens/unreached/IMG-20240207-WA0063_jfk5zx.jpg',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1727811995/blaizingTeens/unreached/IMG-20240207-WA0060_skyjet.jpg',
    ],
    eventDetails: {
      date: '5th February, 2024',
      type: 'Education & Charity',
      venue: 'Community Secondary Commercial School, Iffe Town, Mkpat Enin LGA, Akwa Ibom',
      attendees: '643 Students',
    }
  },
];

export default eventData;
